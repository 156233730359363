import { _ as __decorate } from '../../tslib.es6-M1zqH4el.js';
import { LitElement, html } from 'lit';
import { state, property, customElement } from 'lit/decorators.js';

let Enter = class Enter extends LitElement {
    constructor() {
        super(...arguments);
        this.inView = false;
        this.type = 'fade';
        this.enterOnScroll = false;
        this.delay = '0';
        this.fill = false;
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.enterOnScroll) {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    var _a;
                    if (entry.isIntersecting) {
                        this.inView = true;
                        (_a = this.observer) === null || _a === void 0 ? void 0 : _a.unobserve(this);
                    }
                });
            }, { threshold: 0 });
            this.observer.observe(this);
        }
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.observer) === null || _a === void 0 ? void 0 : _a.disconnect();
    }
    render() {
        const type = this.inView || !this.enterOnScroll ? this.type : '';
        return html `
      <style>
        :host {
          --diamond-transition-enter-delay: ${this.delay}s;
          --diamond-transition-enter-animation: ${type};
        }
      </style>
      <slot></slot>
    `;
    }
};
__decorate([
    state()
], Enter.prototype, "inView", void 0);
__decorate([
    property({ reflect: true })
], Enter.prototype, "type", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'enter-on-scroll' })
], Enter.prototype, "enterOnScroll", void 0);
__decorate([
    property({ reflect: true })
], Enter.prototype, "delay", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Enter.prototype, "fill", void 0);
Enter = __decorate([
    customElement('diamond-enter')
], Enter);

export { Enter };
